import React, { useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import { File } from 'Components/Form';
import Icon from 'Components/Icon';

const Wrapper = styled('section')``;
const Title = styled('h4')``;
const Instructions = styled('p')``;
const Filename = styled('div')``;
const FilenameWrapper = styled('div')``;
const FileWrapper = styled('div')``;

const MediaUploader = ({ className }) => {
  const [fileName, setFileName] = useState('');

  const handleChange = file => {
    setFileName(file.name);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
  };

  return (
    <article className={className}>
      <Wrapper>
        <FileWrapper>
          <File accept="application/pdf" name="pdf" onChange={handleChange} />
          {fileName && (
            <FilenameWrapper>
              <Icon name="file" />
              <Filename>{fileName}</Filename>
            </FilenameWrapper>
          )}
        </FileWrapper>
      </Wrapper>
    </article>
  );
};

MediaUploader.propTypes = {
  className: PropTypes.string,
};

export default styled(MediaUploader)`
  margin-bottom: 3.5rem;

  ${FilenameWrapper} {
    margin-top: 1rem;

    ${Filename} {
      color: ${theme('--color-primary-80')};
      display: inline-block;
      margin: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 24rem;
    }
  }

  @media (${theme('--column-12')}) {
    ${Wrapper} {
      display: flex;
      flex-direction: column;
      justify-content: center;

      ${Title} {
        ${theme('--font-medium')}
        ${theme('--font-weight-medium')}
        margin-bottom: 1rem;
      }

      ${Instructions} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        color: ${theme('--color-dark-night-60')};
        margin-bottom: 1rem;
      }

      ${FileWrapper} {
        display: flex;

        ${FilenameWrapper} {
          display: flex;
          margin-top: 0;

          ${Icon} {
            height: 40px;
            margin: auto 0 auto 2rem;
          }

          ${Filename} {
            margin: auto 0 auto 0.5rem;
            width: 30rem;
          }
        }
      }

      ${File} {
        ${theme('--font-medium')}
        ${theme('--font-weight-medium')}
        background: ${theme('--color-light')};
        border: 0.1rem solid ${theme('--color-primary')};
        border-radius: 0.6rem;
        color: ${theme('--color-primary')};
        cursor: pointer;
        padding: 1.2rem;
        text-align: center;
        width: 14.4rem;
      }
    }
  }
`;
