import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import { Row, Column } from 'Components/ResponsiveTable';

const HeaderTemplate = ({ className, ...common }) => (
  <Row className={className}>
    <Column id="NAME" {...common} />
    <Column id="DESCRIPTION" />
    <Column id="TYPE" />
    <Column id="DATE_UPLOADED" />
  </Row>
);

HeaderTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(HeaderTemplate)`
  grid-template-columns: 2fr 3fr 11rem 20.2rem;
  padding: 1.2rem 2.4rem 0.8rem 2.4rem;
`;
