import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from '@react-intl';
import styled, { withStyles as css, theme } from '@styled-components';

import { me } from 'Selectors';

import View from 'Components/View';
import ViewHeadline from 'Components/ViewHeadline';

import FaqStudio from 'Views/FaqStudio';
import BrandContent from 'Views/BrandContent';
import MicroappStudio from 'Views/MicroappStudio';
import MultipleKnowledgeBase from 'Views/MultipleKnowledgeBase';
import UserGeneratedContent from 'Views/UserGeneratedContent';

import Navbar from './components/Navbar';

const Content = styled('div')``;

const BRAND_CONTENT = 'BRAND_CONTENT';
const FAQ_STUDIO = 'FAQ_STUDIO';
const KNOWLEDGE_BASE = 'KNOWLEDGE_BASE';
const MICROAPPS_BUILDER = 'MICROAPPS_BUILDER';
const USER_GENERATED_CONTENT = 'USER_GENERATED_CONTENT';

const Builder = ({ className, user: { fullName } }) => {
  const [tab, setTab] = useState(KNOWLEDGE_BASE);

  const makeSelectTab = useCallback(
    name => () => {
      setTab(name);
    },
    [setTab],
  );

  return (
    <View className={className}>
      <ViewHeadline
        customHeading={() => (
          <>
            <FormattedMessage capitalize id="INSTRUCTIONS.BOT_BUILDER" />
            <a href="mailto:support@joinedapp.com">support@joinedapp.com</a>
          </>
        )}
        title="WELCOME_BACK_%NAME%"
        values={{
          name: fullName,
        }}
      />

      <Navbar
        current={tab}
        makeSelect={makeSelectTab}
        tabs={[
          KNOWLEDGE_BASE,
          FAQ_STUDIO,
          BRAND_CONTENT,
          USER_GENERATED_CONTENT,
          MICROAPPS_BUILDER,
        ]}
      />
      <Content>
        {tab === KNOWLEDGE_BASE && <MultipleKnowledgeBase />}
        {tab === FAQ_STUDIO && <FaqStudio />}
        {tab === BRAND_CONTENT && <BrandContent />}
        {tab === MICROAPPS_BUILDER && <MicroappStudio />}
        {tab === USER_GENERATED_CONTENT && <UserGeneratedContent />}
      </Content>
    </View>
  );
};

Builder.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    fullName: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  user: me(state),
});

export default compose(
  css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    ${ViewHeadline}, ${Navbar}, ${Content} {
      grid-column: span 4;
    }

    ${Navbar} > * {
      height: 4rem;
    }

    ${ViewHeadline} {
      display: none;
    }

    @media (${theme('--screen-large')}) {
      grid-template-columns: repeat(12, 1fr);

      ${ViewHeadline} {
        display: block;
        grid-column: span 12;
        grid-row: 1;
      }

      ${Navbar} {
        grid-column: span 3;
        grid-row: 2;
        & > * {
          height: 6.4rem;
        }
      }

      ${Content} {
        grid-column: span 9;
        grid-row: 2 / span 2;
      }
    }
  `,
  connect(mapStateToProps),
)(Builder);
