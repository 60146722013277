import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createEditor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import { FormattedMessage } from 'react-intl';

const ButtonBlock = styled('div')``;
const SmallTextAreaBlock = styled('div')``;
const Index = styled('div')``;
const HorizontalLine = styled('div')``;
const TextAreaBlock = styled('div')``;
const Counter = styled('p')``;
const SmsPlaceholder = styled('p')``;

const withKeywordPlaceholder = editor => {
  const { isInline, isVoid } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = element => {
    return element.type === 'keyword-placeholder' ? true : isInline(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = element => {
    return element.type === 'keyword-placeholder' ? true : isVoid(element);
  };

  return editor;
};

const Element = props => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'keyword-placeholder':
      return <KeywordPlaceholder {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const KeywordPlaceholder = ({ attributes, children, element }) => {
  const selected = false;
  const focused = false;
  return (
    <span
      {...attributes}
      className="keywordPlaceholder"
      contentEditable={false}
      style={{
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        fontSize: '0.9em',
      }}
    >
      {element.placeholder}
      {children}
    </span>
  );
};

const createKeywordPlaceholder = placeholder => ({
  children: [{ text: '' }],
  placeholder,
  type: 'keyword-placeholder',
});

const insertKeywordPlaceholder = (editor, keywordPlaceholder) => {
  Transforms.insertNodes(editor, keywordPlaceholder);
  Transforms.move(editor);
};

function MyEditor({
  className,
  defaultValue,
  index,
  limit,
  onChange,
  options,
  smsPlaceholder,
  type,
}) {
  const editor = useMemo(
    () => withKeywordPlaceholder(withHistory(withReact(createEditor()))),
    [],
  );

  const renderElement = useCallback(props => <Element {...props} />, []);

  const [value, setValue] = useState(null);
  const [counter, setCounter] = useState(defaultValue?.length || 0);

  useEffect(() => {
    const valueArr = defaultValue
      ? defaultValue.split(
          /(?=<%=firstName%>)|(?<=<%=firstName%>)|(?=<%=lastName%>)|(?<=<%=lastName%>)|(?=<%=emailAddress%>)|(?<=<%=emailAddress%>)|(?=<%=phoneNumber%>)|(?<=<%=phoneNumber%>)(?=<%=campaignLink%>)|(?<=<%=campaignLink%>)/,
        )
      : [];

    setValue([
      {
        children: [
          ...(valueArr?.map(part => {
            const match = part.match(
              /<%=(firstName|lastName|emailAddress|phoneNumber|campaignLink)%>/,
            );
            if (!match) {
              return { text: part };
            }
            const keywordPlaceholderObj = options.find(
              keyword => keyword.value === match[1],
            );
            return createKeywordPlaceholder(keywordPlaceholderObj.name);
          }) || []),
          { text: '' },
        ],
      },
    ]);
  }, []); // eslint-disable-line

  const onKeyDown = event => {
    if (counter >= limit && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  function handleInsertClick(keywordValue) {
    const keywordPlaceholderObj = options.find(
      keyword => keyword.value === keywordValue,
    );

    if (counter + keywordPlaceholderObj.name.length + 3 <= limit) {
      insertKeywordPlaceholder(
        editor,
        createKeywordPlaceholder(keywordPlaceholderObj.name),
      );
    }
  }

  function convertToServerFormat(editorChildren) {
    const serverDataArr = [];

    editorChildren.forEach(topChild => {
      const row = topChild.children.reduce((acc, child) => {
        if (child.type === 'keyword-placeholder') {
          const keywordPlaceholderObj = options.find(
            keyword => keyword.name === child.placeholder,
          );

          return `${acc}<%=${keywordPlaceholderObj.value}%>`;
        }

        return acc + child.text;
      }, '');

      serverDataArr.push(row);
    });

    return serverDataArr.join('\n');
  }

  const handlePaste = async event => {
    event.preventDefault();
    const clipboard = await navigator.clipboard.readText();

    if (clipboard.length + counter >= limit) {
      editor.insertText(clipboard.slice(0, limit - counter));
    } else {
      editor.insertText(clipboard);
    }
  };

  const handleChange = rawValue => {
    const serverValue = convertToServerFormat(rawValue);
    onChange(serverValue, index);
    setCounter(serverValue.length);
  };

  return (
    <div className={className}>
      <ButtonBlock>
        {options.map(option => {
          const handleButtonClick = () => {
            if (option.onClick) {
              option.onClick({ cb: () => handleInsertClick(option.value) });
            } else {
              handleInsertClick(option.value);
            }
          };

          return (
            <Button
              key={option.value}
              capitalize
              primary
              secondary
              translate
              onClick={handleButtonClick}
            >
              {option.name}
            </Button>
          );
        })}
      </ButtonBlock>
      {value ? (
        <>
          {type === 'WHATSAPP' ? (
            <SmallTextAreaBlock>
              <Index>{`{{${index}}}`}</Index>
              <HorizontalLine>
                <hr />
              </HorizontalLine>
              <Slate editor={editor} onChange={handleChange} value={value}>
                <TextAreaBlock>
                  <Editable
                    className={`text-area ${type === 'WHATSAPP' && 'whatsapp'}`}
                    onKeyDown={onKeyDown}
                    onPaste={handlePaste}
                    placeholder={
                      <FormattedMessage
                        capitalize
                        component={SmsPlaceholder}
                        id={smsPlaceholder}
                      >
                        {smsPlaceholder}
                      </FormattedMessage>
                    }
                    renderElement={renderElement}
                  />
                </TextAreaBlock>
              </Slate>
            </SmallTextAreaBlock>
          ) : (
            <Slate editor={editor} onChange={handleChange} value={value}>
              <TextAreaBlock>
                <Editable
                  className={`text-area ${type === 'WHATSAPP' && 'whatsapp'}`}
                  onKeyDown={onKeyDown}
                  onPaste={handlePaste}
                  placeholder={
                    <FormattedMessage
                      capitalize
                      component={SmsPlaceholder}
                      id={smsPlaceholder}
                    >
                      {smsPlaceholder}
                    </FormattedMessage>
                  }
                  renderElement={renderElement}
                />
                <Counter>
                  {counter}/{limit}
                </Counter>
              </TextAreaBlock>
            </Slate>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  element: PropTypes.object,
};

KeywordPlaceholder.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  element: PropTypes.object,
};

MyEditor.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  index: PropTypes.number,
  limit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  smsPlaceholder: PropTypes.string,
  type: PropTypes.string,
};

export default styled(MyEditor)`
  ${SmallTextAreaBlock} {
    display: grid;
    grid-template-columns: auto 0.5fr 8fr;
    margin-bottom: 1.6rem;

    ${Index} {
      background: #CCDFFF;
      border-radius: 0.6rem;
      margin: auto;
      padding: 8px;
    }

    ${HorizontalLine} {
      margin: auto;
      width: 100%;
    }
  }

  .text-area {
    ${theme('--font-opacity-100')}
    background: ${theme('--color-light')};
    border-color: ${theme('--color-dark-night-10')};
    border-radius: 0.6rem;
    border-style: solid;
    border-width: 0.1rem;
    color: #666F75;
    height: 16.5rem;
    line-height: 2.4rem;
    outline: 0;
    overflow-y: auto;
    padding: 1.6rem;

    &.whatsapp {
      height: 6rem;
    }

    &::before {
      border-radius: 0.6rem;
      content: '';
      display: block;
      height: 3.2rem;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:focus-within {
      border-color: ${theme('--color-primary')};
    }
  }

  ${ButtonBlock} {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.8rem;
    margin-bottom: 1.6rem;

    ${Button} {
      background-color: ${theme('--color-light')};
      border: 1px solid #99beff;
      border-radius: 0.4rem;
      font-size: 1.2rem;
      font-weight: 400;
      height: 2.4rem;
      padding: 0 1.2rem;
      text-align: center;
    }
  }

  ${TextAreaBlock} {
    color: #666F75;
    font-size: 1.4rem;
    position: relative;
  }

  ${Counter} {
    bottom: 0.8rem;
    color: #999FA3;
    left: 1.6rem;
    position: absolute;
    z-index: 10;
  }

  .keywordPlaceholder {
    background: #CCDFFF;
    border-radius: 0.4rem;
    color: #005DFF;
    display: inline-block;
    font-size: 1.2rem;
    height: 2.4rem;
    margin: 0 0.1rem;
    padding: 0 0.5rem;
    text-align: center;
    vertical-align: baseline;
  }
`;
