import React, { useState, useMemo, useCallback } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from '@react-intl';
import { useHistory } from 'react-router-dom';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Dialog from 'Components/Dialog';
import Form, { Input, Select } from 'Components/Form';
import Button from 'Components/Button';
import ButtonLoader from 'Components/ButtonLoader';
import Loader from 'Components/Loader';

import { currentAccount } from 'Selectors';

import service from 'Services/knowledge-base';

import MediaUploader from './MediaUploader';
import MyEditor from './SmsTextArea';
import ProgressBar from '../../components/ProgressBar';

const Actions = styled('div')``;
const SectionTitle = styled('h4')``;
const SectionDescription = styled('p')``;
const SelectWrapper = styled('div')``;

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const AddKnowledgeBase = ({ account, className, in: isIn, onClose }) => {
  const [formValues, setFormValues] = useState({});
  const [isSubmitable, setIsSubmitable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('PDF');
  const { refetch } = useMultiDataProviderContext('multipleKnowledge');

  const [progress, setProgress] = useState(0);

  const { location: { state: { locale = 'en_US' } = {} } = {} } = useHistory();
  const intl = useIntl();

  const handleClick = async () => {
    setIsSubmitable(false);
    setIsLoading(true);

    const formData = new FormData();
    formData.append('type', formValues.type);
    formData.append('name', formValues.name);
    if (formValues[type.toLowerCase()])
      formData.append([type.toLowerCase()], formValues[type.toLowerCase()]);
    if (formValues.description)
      formData.append('description', formValues.description);

    await service.add({ account, locale, media: formData, setProgress });

    setIsSubmitable(true);
    setIsLoading(false);
    onClose();

    if (refetch) {
      refetch();
    }
  };

  const handleFormChange = formData => {
    const temp = {
      ...formValues,
      ...formData,
    };

    setFormValues(temp);
  };

  const handleChange = value => {
    const temp = {
      ...formValues,
      [type.toLowerCase()]: value,
    };
    setFormValues(temp);
  };

  const isDisabled =
    !formValues[type.toLowerCase()] || !formValues.name || !isSubmitable;

  const filterOptions = useMemo(
    () => [
      {
        name: intl.formatMessage({ capitalize: true, id: 'PDF' }),
        value: 'PDF',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'TEXT' }),
        value: 'TEXT',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'URL' }),
        value: 'URL',
      },
    ],
    [intl],
  );

  const handleType = useCallback(
    t => {
      const temp = {
        ...formValues,
      };

      delete temp.pdf;
      delete temp.url;
      delete temp.text;

      setFormValues(temp);
      setType(t);
    },
    [setType, formValues],
  );

  return (
    <Dialog
      className={className}
      disabled={isLoading}
      in={isIn}
      onDismiss={onClose}
      title="ADD_KNOWLEDGE_SOURCE"
    >
      <Form onChange={handleFormChange}>
        <FormattedMessage
          capitalize
          component={SectionTitle}
          id="INSTRUCTIONS.UPLOAD_KNOWLEDGE_BASE"
        />
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.UPLOAD_KNOWLEDGE_BASE.DESCRIPTION"
        />
        <SelectWrapper>
          <Select
            capitalize
            translate
            label="TYPE"
            name="type"
            onChange={handleType}
            options={filterOptions}
            type="type"
          />
        </SelectWrapper>
        {type === 'PDF' && <MediaUploader />}
        {type === 'TEXT' && (
          <MyEditor
            limit={2048}
            name="text"
            onChange={handleChange}
            placeholder="ADD_KNOWLEDGE_TEXT_PLACEHOLDER"
            type="TEXT"
          />
        )}
        {type === 'URL' && (
          <MyEditor
            limit={60}
            name="url"
            onChange={handleChange}
            placeholder="URL"
            type="URL"
          />
        )}

        <FormattedMessage
          capitalize
          component={SectionTitle}
          id="INSTRUCTIONS.UPLOAD_KNOWLEDGE_BASE.FORM_TITLE"
        />
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.UPLOAD_KNOWLEDGE_BASE.FORM_DESCRIPTION"
        />
        <Input label="NAME" name="name" />
        <Input label="DESCRIPTION" name="description" />

        {type === 'PDF' && isLoading && (
          <>
            <Loader height={100} width={100} />
            <ProgressBar filename={formValues.pdf.name} progress={progress} />
          </>
        )}

        <Actions>
          <FormattedMessage
            capitalize
            primary
            component={ButtonLoader}
            disabled={isDisabled}
            id="ACTIONS.UPLOAD"
            onClick={handleClick}
          />
          <FormattedMessage
            capitalize
            secondary
            component={Button}
            disabled={isDisabled}
            id="ACTIONS.CANCEL"
            onClick={onClose}
          />
        </Actions>
      </Form>
    </Dialog>
  );
};

AddKnowledgeBase.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  in: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps)(styled(AddKnowledgeBase)`
  ${Form} {
    margin-top: 1.5rem;
  }

  ${Dialog} {
    &[disabled] {
      border-color: ${theme('--color-dark-night-20')};
      ${theme('--font-opacity-40')}
    }
  }

  ${SelectWrapper} {
    margin-bottom: 1.5rem;
    width: 11rem;
  }

  ${Input} {
    margin-top: 0.8rem;
  }

  ${SectionTitle} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    margin-bottom: 1rem;
  }

  ${SectionDescription} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
    margin-bottom: 1rem;
  }

  ${Actions} {
    display: grid;
    grid-gap: 0.8rem;
    grid-template: auto / 1fr 1fr;
    margin-top: 3rem;

    ${Button} {
      padding: 1.4rem;
    }
  }

  @media (${theme('--screen-small')}) {
    ${Actions} {
      display: block;

      ${(Button, ButtonLoader)} {
        padding: 1.4rem;
        width: 17rem;

        &:not(:last-child) {
          margin-right: 1.6rem;
        }
      }
    }
  }
`);
