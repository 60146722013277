import request from 'Providers/request';
import axiosApi from 'Providers/axiosApi';
import { createService } from '@redux-act';

const locales = createService(({ account }, token) =>
  request.read(`/ofa/${account}/faq_locales`, {}, {}, { token }),
);

async function get({ account, locale, searchBy, type }, token) {
  const response = await request.read(
    `/crm/${account}/qna/knowledgebase`,
    {
      locale,
      ...(searchBy && { searchBy }),
      ...(type && { type }),
    },
    {},
    { token },
  );

  return response;
}

async function getDetail({ account, id }, token) {
  const response = await request.read(
    `/crm/${account}/qna/knowledgebase/${id}`,
    {},
    { token },
  );

  return response;
}

function add({ account, locale, media, setProgress }) {
  return axiosApi.post(`/crm/${account}/importKb?locale=${locale}`, media, {
    headers: {
      'content-type': 'form-data/multipart',
    },
    onUploadProgress: progressEvent => {
      const totalProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setProgress(totalProgress);
    },
  });
}

async function editDescription({ account, description, id }) {
  const response = await request(
    `/crm/${account}/qna/knowledgebase/${id}`,
    'put',
    { description },
  );

  return response;
}

async function remove({ account, id }) {
  const response = await request(`/ofa/${account}/faq/${id}`, 'delete', {});

  return response;
}

export default {
  add,
  editDescription,
  get,
  getDetail,
  locales,
  remove,
};
