import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';

import Icon from 'Components/Icon';

const ProgressArea = styled('div')``;
const ProgressStatus = styled('progress')``;
const ProgressDetail = styled('div')``;
const ProgressName = styled('div')``;

const ProgressBar = ({ className, filename, progress }) => {
  return (
    <div className={className}>
      <ProgressArea>
        <Icon name="file" />
        <div style={{ width: '85%' }}>
          <ProgressDetail>
            <ProgressName>{filename}</ProgressName>
            {progress === 100 ? <div>Success</div> : <div>{progress}%</div>}
          </ProgressDetail>
          <ProgressStatus id="progressStatus" max="100" value={progress} />
        </div>
      </ProgressArea>
    </div>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  filename: PropTypes.string,
  progress: PropTypes.number,
};

export default styled(ProgressBar)`
  ${ProgressArea} {
    display: flex;
    flex-direction: row;
    margin-top: 3rem;

    ${ProgressDetail} {
      display: flex;
      justify-content: space-between;

      ${ProgressName} {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 60%;
      }
    }

    ${Icon} {
      margin-right: 1rem;
    }

    ${ProgressStatus} {
      font-size: 1.6rem;
      width: 100%;
    }
  }
`;
