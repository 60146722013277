import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { compose } from 'underscore';
import moment from 'moment';

import Placeholder from 'Containers/Placeholder';

import { Row } from 'Components/ResponsiveTable';

const Field = styled(Placeholder.div)``;
const NameField = styled(Field)``;
const Label = styled(Placeholder)``;
const DescriptionField = styled(Label)``;
const DateUploadedLabel = styled(Label)``;
const TypeLabel = styled(Label)``;

const DesktopTemplate = ({
  className,
  description,
  empty,
  name,
  id,
  type,
  uploadDate,
}) => {
  return (
    <Row
      className={className}
      empty={empty}
      to={`/builder/knowledgebase/${id}`}
    >
      <NameField placeholder="placeholder">{name}</NameField>
      <DescriptionField placeholder="placeholder">
        {description}
      </DescriptionField>
      <TypeLabel placeholder="placeholder">{type}</TypeLabel>
      <DateUploadedLabel placeholder="placeholder">
        {moment(uploadDate).format('LLL')}
      </DateUploadedLabel>
    </Row>
  );
};

DesktopTemplate.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  uploadDate: PropTypes.string,
};

export default compose(
  css`
    align-items: flex-start;
    grid-template-columns: 2fr 3fr 11rem 20.2rem;
    min-height: 7.2rem;
    text-align: left;

    ${Field} {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    ${Label} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-80')}
      align-items: center;
      display: flex;
      height: 100%;
      min-height: 5.6rem;
      padding: 1.2rem 0.8rem 1.2rem 0;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    ${DateUploadedLabel} {
      align-items: center;
      height: 100%;
    }
  `,
)(DesktopTemplate);
