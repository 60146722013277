import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import moment from 'moment';

import Placeholder from 'Containers/Placeholder';

import { Row } from 'Components/ResponsiveTable';

const Heading = styled(Placeholder.h3)``;
const NameHeading = styled(Heading)``;
const DescriptionHeading = styled(Heading)``;
const TypeHeading = styled(Heading)``;
const DateHeading = styled(Heading)``;
const Label = styled(Placeholder)``;
const NameLabel = styled(Label)``;
const DescriptionLabel = styled(Label)``;
const DateUploadedLabel = styled(Label)``;
const TypeLabel = styled(Label)``;

const DesktopTemplate = ({
  className,
  description,
  empty,
  name,
  id,
  type,
  uploadDate,
}) => {
  return (
    <Row
      className={className}
      empty={empty}
      to={`/builder/knowledgebase/${id}`}
    >
      <NameHeading placeholder="placeholder">Name</NameHeading>
      <DescriptionHeading placeholder="placeholder">
        Description
      </DescriptionHeading>
      <TypeHeading placeholder="placeholder">Type</TypeHeading>
      <DateHeading placeholder="placeholder">Date uploaded</DateHeading>
      <NameLabel placeholder="placeholder">{name}</NameLabel>
      <DescriptionLabel placeholder="placeholder">
        {description}
      </DescriptionLabel>
      <TypeLabel placeholder="placeholder">{type}</TypeLabel>
      <DateUploadedLabel placeholder="placeholder">
        {moment(uploadDate).format('LLL')}
      </DateUploadedLabel>
    </Row>
  );
};

DesktopTemplate.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  uploadDate: PropTypes.string,
};

export default styled(DesktopTemplate)`
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 12rem auto;
  margin: 0 2.4rem;
  padding: 1.2rem 0;

  ${Heading} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}
    grid-column: 1;
  }

  ${NameHeading} {
    grid-row: 1;
  }

  ${DescriptionHeading} {
    grid-row: 2;
  }

  ${TypeHeading} {
    grid-row: 3;
  }

  ${DateHeading} {
    grid-row: 4;
  }

  ${Label} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    ${theme('--font-opacity-80')}
    grid-column: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${NameLabel} {
    grid-row: 1;
  }

  ${DescriptionLabel} {
    grid-row: 2;
  }

  ${TypeLabel} {
    grid-row: 3;
  }

  ${DateUploadedLabel} {
    grid-row: 4;
  }
`;
