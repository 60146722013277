import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Table, { Body } from 'Components/ResponsiveTable';

import DesktopTemplate from './DesktopTemplate';
import EmptyTemplate from './EmptyTemplate';
import EmptyTemplateFilters from './EmptyTemplateFilters';
import Header from './Header';
import Template from './Template';

const KnowledgeTable = ({
  className,
  data: { collection: knowledge = [] } = {},
  filterAmmount,
  onCursorChange: handleCursorChange,
  options,
  role,
  ...props
}) => {
  return (
    <Table
      {...props}
      className={className}
      desktopRows={8}
      desktopTemplate={DesktopTemplate}
      emptyTemplate={filterAmmount ? EmptyTemplateFilters : EmptyTemplate}
      header={Header}
      items={knowledge}
      onCursorChange={handleCursorChange}
      options={options}
      overflow="visible"
      role={role}
      rows={5}
      template={Template}
    />
  );
};

KnowledgeTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    knowledge: PropTypes.arrayOf(PropTypes.object),
  }),
  filterAmmount: PropTypes.number,
  onCursorChange: PropTypes.func,
  options: PropTypes.object,
  role: PropTypes.string,
};

export default styled(KnowledgeTable)`
  ${Body} {
    overflow: visible;
  }
`;
