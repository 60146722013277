import React, { useState, useCallback, useMemo } from 'react';
import { name as localeNames } from '@langs';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { useIntl } from '@react-intl';
import { useHistory } from 'react-router-dom';

import Button from 'Components/Button';
import Card from 'Components/Card';
import Empty from 'Components/Empty';
import { AutoSuggest, Select, Input } from 'Components/Form';

import TableDataProvider from 'Containers/TableDataProvider';
import Toolbar, { SoftLink } from 'Containers/CardToolbar';

import service from 'Services/knowledge-base';

import Table from './components/Table';

const ConfirmRemove = styled('p')``;
const UploadButton = styled(Button)``;

const BotBuilder = ({ account, className, locales = [] }) => {
  const [locale, setLocale] = useState(locales[0]);
  const [tagFilters, setTagFilter] = useState([]);
  const [searchBy, setSearch] = useState();
  const [type, setType] = useState('');

  const intl = useIntl();
  const history = useHistory();

  const handleSearch = useCallback(
    search => {
      setSearch(search);
    },
    [setSearch],
  );

  const handleType = useCallback(
    t => {
      setTagFilter([]);
      setType(t);
    },
    [setTagFilter, setType],
  );

  const filterOptions = useMemo(
    () => [
      {
        name: intl.formatMessage({ capitalize: true, id: 'ALL_TYPES' }),
        value: '',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'PDF' }),
        value: 'PDF',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'TEXT' }),
        value: 'TEXT',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'URL' }),
        value: 'URL',
      },
    ],
    [intl],
  );

  let filterAmmount = 0;

  if (searchBy) {
    filterAmmount += 1;
  }

  if (type) {
    filterAmmount += 1;
  }

  if (tagFilters.length) {
    filterAmmount += 1;
  }

  const options = {
    account,
    locale,
  };

  return (
    <>
      <div className={className}>
        <>
          <Select
            onChange={setLocale}
            options={locales.map(key => ({
              name: localeNames(key),
              value: key,
            }))}
          />
          <UploadButton
            capitalize
            primary
            translate
            onClick={() => {
              history.push({
                pathname: '/builder/knowledgebase/add',
                state: { locale },
              });
            }}
          >
            ADD_KNOWLEDGE_SOURCE
          </UploadButton>
        </>
      </div>
      <div className={className}>
        <Card>
          <Toolbar filterAmmount={filterAmmount} title="KNOWLEDGE_BASE">
            <Input
              capitalize
              label="SEARCH"
              name="search"
              onChange={handleSearch}
              type="search"
            />
            <Select
              capitalize
              translate
              label="TYPE"
              name="type"
              onChange={handleType}
              options={filterOptions}
              type="type"
            />
          </Toolbar>
          <TableDataProvider
            account={account}
            filterAmmount={filterAmmount}
            limit={9}
            locale={locale}
            name="multipleKnowledge"
            options={options}
            params={{
              account,
              filterBy: tagFilters.map(({ name }) => name),
              locale,
              searchBy,
              type,
            }}
            query={service.get}
            setLocale={setLocale}
            template={Table}
          />
        </Card>
      </div>
    </>
  );
};

BotBuilder.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.string),
};

export default styled(BotBuilder)`
  display: grid;
  grid-gap: 1.6rem;
  grid-template-rows: auto minmax(0, 1fr);
  margin-bottom: 1.6rem;

  ${Card} {
    display: grid;
    grid-column: span 4;
    grid-template-rows: auto minmax(0, 1fr);
    padding: 0;

    ${Toolbar} {
      ${AutoSuggest}, ${Input}, ${Select} {
        display: inline-block;
        height: 4.8rem;
        margin: 0.4rem 0;
        width: 100%;
      }
    }

    ${Empty} {
      height: calc(100vh - (18.4rem + 2 * 3.2rem));
    }
  }

  ${SoftLink} {
    position: absolute;
    visibility: hidden;
  }

  ${ConfirmRemove} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-60')}
  }

  @media (${theme('--column-8')}) {
    ${UploadButton} {
      grid-column: 12;
    }

    ${Card} {
      grid-column: span 8;
      overflow: visible;

      ${Toolbar} {
        ${AutoSuggest}, ${Input}, ${Select} {
          margin: 0 0 0 1.6rem;
          width: 20rem;
        }

        ${AutoSuggest}{
          ${Input} {
            margin: 0;
          }
        }
      }
    }

    ${SoftLink} {
      position: relative;
      visibility: visible;
    }
  }

  @media (${theme('--column-12')}) {
    ${UploadButton} {
      grid-column: 12;
    }

    ${Card} {
      grid-column: span 12;
    }
  }
`;
