import axios from 'axios';
import storage from './storage';
import C from 'Constants';

const { STORE } = C;
const { host, protocol } = window.location;
const basename = host.replace('admin.', '');

const API = process.env.API_URI || `${protocol}//call.${basename}/api/v1.0`;

export default function axiosApi() {
  const {
    session: { id, token },
  } = storage.get(STORE);

  const credentials = token ? { authKey: token, id } : {};

  return axios.create({
    baseURL: API,
    headers: {
      ...credentials,
    },
  });
}
