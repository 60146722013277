import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Placeholder from 'Containers/Placeholder';

import { Row } from 'Components/ResponsiveTable';
import TagList from 'Components/TagList';

const Heading = styled(Placeholder.h3)``;
const QHeading = styled(Heading)``;
const AHeading = styled(Heading)``;
const Label = styled(Placeholder)``;
const QuestionWrapper = styled(Label)``;
const AnswerWrapper = styled(Label)``;
const QuestionCount = styled(Heading)``;
const TagLabel = styled(Label)``;

const DesktopTemplate = ({
  answer = {},
  className,
  empty,
  id,
  questions = [],
  tags = [],
  type,
}) => {
  const { content, type: aType } = answer;
  const questionCount = questions.length;
  const question = questions[0] || {};

  return (
    <Row
      className={className}
      empty={empty}
      to={`/builder/faqstudio/${type}_${id}`}
    >
      <QHeading capitalize translate placeholder="placeholder">
        QUESTION
      </QHeading>
      <AHeading>
        {aType === 'TEXT' ? (
          <Label capitalize translate placeholder="placeholder">
            RESPONSE_TEXT
          </Label>
        ) : (
          <Label capitalize translate placeholder="placeholder">
            RESPONSE_CUSTOM
          </Label>
        )}
      </AHeading>
      <TagLabel placeholder="placeholder">
        <TagList fill tags={tags} />
      </TagLabel>
      <QuestionWrapper>{question.text}</QuestionWrapper>
      <AnswerWrapper placeholder="placeholder">{content}</AnswerWrapper>
      <QuestionCount placeholder="placeholder">{questionCount}</QuestionCount>
    </Row>
  );
};

DesktopTemplate.propTypes = {
  answer: PropTypes.object,
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.string,
  questions: PropTypes.array,
  tags: PropTypes.array,
  type: PropTypes.string,
};

export default styled(DesktopTemplate)`
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 7rem 1fr auto;
  height: 12rem;
  margin: 0 2.4rem;
  padding: 1.2rem 0;

  ${Heading} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}
    grid-column: 1;
  }

  ${QHeading} {
    grid-row: 1;
  }

  ${AHeading} {
    grid-row: 2;
  }

  ${TagLabel} {
    grid-column: 1 / span 3;
    grid-row: 3;
    height: 10rem;
    ${TagList} {
      width: 100%;
    }
  }

  ${QuestionWrapper} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    ${theme('--font-opacity-80')}
    grid-column: 2;
    grid-row: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${AnswerWrapper} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-80')}
    grid-column: 2 / span 2;
    grid-row: 2;
    max-height: 3.2rem;
    overflow: hidden;
  }

  ${QuestionCount} {
    grid-column: 3;
    grid-row: 1;
  }
`;
