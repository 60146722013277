import React, { useEffect, useState } from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import moment from 'moment';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import FileTemplate from './FileTemplate';

import Button from 'Components/Button';
import Card from 'Components/Card';
import Icon from 'Components/Icon';
import { Input, Select } from 'Components/Form';

import service from 'Services/knowledge-base';

import DataText from './DataText';
import SectionHeadline from './SectionHeadline';

const DesripctionCard = styled(Card)``;
const DocumentDesripctionCard = styled('div')``;
const InformationCard = styled(Card)``;
const OverviewBlock = styled('div')``;
const OverviewHeadline = styled(SectionHeadline)``;
const FileCard = styled(Card)``;
const SaveButton = styled(Button)``;
const DownloadButton = styled(Button)``;

const PdfViewer = ({ account, className, data, handleRefetch, locale }) => {
  const [mainData, setMainData] = useState(data);
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState(mainData.description);

  useEffect(() => {
    setMainData(data);
  }, [data]);

  const { refetch } = useMultiDataProviderContext('knowledgeBaseDetail');

  const handleSave = async () => {
    await service.editDescription({ account, description, id: mainData.id });

    if (refetch) {
      refetch();
    }
    setEdit(false);
    handleRefetch();
  };

  return (
    <div className={className}>
      <FileCard>
        <FileTemplate data={mainData} locale={locale} />
      </FileCard>
      <InformationCard>
        <OverviewBlock>
          <OverviewHeadline title="INFORMATION" />
          <DataText heading={mainData.name || 'N/A'} title="NAME" />
          <DataText
            heading={
              mainData.uploadDate
                ? moment(mainData.uploadDate).format('LLL')
                : 'N/A'
            }
            title="DATE_UPLOADED"
          />
          <DataText
            heading={mainData.type ? mainData.type : 'N/A'}
            title="FILE_TYPE"
          />
        </OverviewBlock>
        {data.type === 'PDF' && (
          <a href={data.url} rel="noopener noreferrer" target="_blank">
            <DownloadButton capitalize secondary translate>
              DOWNLOAD_DOCUMENT
            </DownloadButton>
          </a>
        )}
      </InformationCard>
      <DesripctionCard>
        <div className="descriptionHeader">
          <SectionHeadline
            heading="INSTRUCTIONS.DESCRIPTION_KNOWLEDGE_BASE"
            title="DESCRIPTION"
          />
          {edit === false && (
            <Icon
              name="edit-outline"
              onClick={() => {
                setDescription(mainData.description);
                setEdit(true);
              }}
            />
          )}
        </div>

        {edit ? (
          <>
            <Input
              defaultValue={mainData.description}
              label="DESCRIPTION"
              name="description"
              onChange={e => setDescription(e)}
              value={description}
            />
            <SaveButton capitalize primary translate onClick={handleSave}>
              SAVE
            </SaveButton>
          </>
        ) : (
          <DocumentDesripctionCard>
            {mainData.description || 'N/A'}
          </DocumentDesripctionCard>
        )}
      </DesripctionCard>
    </div>
  );
};

PdfViewer.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.object,
  handleRefetch: PropTypes.func,
  locale: PropTypes.string,
};

export default styled(PdfViewer)`
  display: grid;
  grid-gap: 1.6rem;
  grid-template-rows: auto minmax(0, 1fr);

  ${DownloadButton} {
    width: fit-content;
  }

  ${SaveButton} {
    margin-top: 1.6rem;
    width: 16rem;
  }

  ${SectionHeadline} {
    margin-bottom: 1.6rem;
  }

  .descriptionHeader {
    display: flex;
    justify-content: space-between;
  }

  ${Icon} {
    cursor: pointer;
  }

  ${DocumentDesripctionCard} {
    background-color: #f7f9fc;
    border-radius: 0.6rem;
    padding: 1.6rem;
  }

  @media (${theme('--column-8')}) {
    ${InformationCard} {
      grid-column: span 3;
    }
  }

  @media (${theme('--column-12')}) {
    grid-column: span 12;
    grid-template-columns: repeat(12, 1fr);

    ${Select} {
      width: 18rem;
    }

    ${FileCard} {
      grid-column: span 7;
      grid-row: 2 / span 10;
      height: fit-content;
    }

    ${InformationCard} {
      grid-column: span 5;
      grid-row: 2;
    }

    ${DesripctionCard} {
      display: block;
      grid-column: span 5;
      grid-row: 3;
    }
  }
`;
