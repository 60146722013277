import React, { useState } from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import { withRouter } from 'Components/Router';

import { Document, Page, pdfjs } from 'react-pdf';

import Icon from 'Components/Icon';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DataSection = styled('div')``;
const CopySection = styled('div')``;
const CopyClipboardSection = styled('div')``;

const FileTemplate = ({ className, data }) => {
  const [totalPages, setTotalPages] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const handleCopy = value => {
    navigator.clipboard.writeText(value);
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <div className={className}>
      {data.type === 'PDF' && (
        <div className="pdf-view">
          <Document file={data.url} onLoadSuccess={onDocumentLoadSuccess}>
            {/* eslint-disable-next-line prefer-spread */}
            {Array.apply(null, Array(totalPages))
              .map((x, i) => i + 1)
              .map(page => {
                return (
                  <div
                    style={{
                      border: '5px solid #EEF1F6',
                      marginBottom: '2.6rem',
                    }}
                  >
                    <Page
                      pageNumber={page}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                    <p style={{ margin: '1.6rem 0', textAlign: 'center' }}>
                      Page {page} of {totalPages}
                    </p>
                  </div>
                );
              })}
          </Document>
        </div>
      )}

      {data.type === 'TEXT' && (
        <>
          <FormattedMessage capitalize id="TEXT">
            Text
          </FormattedMessage>
          <DataSection data-open={isOpen}>
            {data.text}
            <CopySection>
              <CopyClipboardSection>
                <FormattedMessage capitalize id="TEXT_COPIED!">
                  Text copied!
                </FormattedMessage>
              </CopyClipboardSection>
              <Icon name="copy" onClick={() => handleCopy(data.text)} />
            </CopySection>
          </DataSection>
        </>
      )}

      {data.type === 'URL' && (
        <>
          <FormattedMessage capitalize id="URL">
            URL
          </FormattedMessage>
          <DataSection data-open={isOpen}>
            <a href={data.url} rel="noopener noreferrer" target="_blank">
              {data.url}
            </a>
            <CopySection>
              <CopyClipboardSection>
                <FormattedMessage capitalize id="TEXT_COPIED!">
                  Text copied!
                </FormattedMessage>
              </CopyClipboardSection>
              <Icon name="copy" onClick={() => handleCopy(data.url)} />
            </CopySection>
          </DataSection>
        </>
      )}
    </div>
  );
};

FileTemplate.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default compose(
  css`
    .pdf-view {
      height: 50rem;
      overflow-y: auto;
    }

    canvas.react-pdf__Page__canvas {
      width: 100% !important;
    }

    ${DataSection} {
      background-color: #f7f9fc;
      border-radius: 0.6rem;
      margin-top: 1rem;
      padding: 1.6rem 1.6rem 1rem 1.6rem;
      word-wrap: break-word;

      ${CopySection} {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
      }

      ${CopyClipboardSection} {
        background-color: white;
        border-radius: 0.6rem;
        margin: auto;
        opacity: 0;
        padding: 0.6rem 1.6rem;
        transition: all 250ms linear;
      }

      &[data-open='true'] {
        ${CopyClipboardSection} {
          opacity: 1;
        }
      }
    }
  `,
  withRouter,
)(FileTemplate);
