import React, { useCallback, useState } from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';

import * as logger from 'Actions/logger';

import Modal from 'Components/Modal';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import { currentAccount, role as getRole } from 'Selectors';

import service from 'Services/knowledge-base';

import TableDataProvider from 'Containers/TableDataProvider';

import PdfViewer from './PdfViewer';
import Toolbar from './components/Toolbar';
import DeleteKnowledge from './components/DeleteKnowledge';

const mapStateToProps = state => ({
  account: currentAccount(state),
  role: getRole(state),
});

const mapDispatchToProps = { error: logger.error, success: logger.success };

const KnowledgeBase = ({
  account,
  order,
  depth,
  id,
  in: modalIn,
  onClose,
  onBack,
  role,
}) => {
  const { refetch } = useMultiDataProviderContext('multipleKnowledge');
  const [deleteVisible, setDeleteVisible] = useState(false);

  const showDelete = useCallback(() => setDeleteVisible(true), []);
  const hideDelete = useCallback(() => setDeleteVisible(false), []);

  const handleDelete = useCallback(async () => {
    await service.remove({ account, id });

    onClose();
    refetch();
  }, [account, id, onClose, refetch]);

  const handleRefetch = () => {
    refetch();
  };

  const isNotReader = role !== 'READER';

  return (
    <>
      <DeleteKnowledge
        in={deleteVisible}
        onAccept={handleDelete}
        onCancel={hideDelete}
      />
      <Modal
        depth={depth}
        in={modalIn}
        onBack={onBack}
        onClose={onClose}
        order={order}
        title="SOURCE_DETAILS"
        // FIXME: Use with permissions in the toolbar to hide it
        {...(isNotReader
          ? { toolbar: () => <Toolbar handleDelete={showDelete} /> }
          : {})}
      >
        <TableDataProvider
          account={account}
          handleRefetch={handleRefetch}
          name="knowledgeBaseDetail"
          params={{
            account,
            id,
          }}
          query={service.getDetail}
          template={PdfViewer}
        />
      </Modal>
    </>
  );
};

KnowledgeBase.propTypes = {
  account: PropTypes.number,
  dataKey: PropTypes.string,
  depth: PropTypes.number,
  error: PropTypes.func,
  id: PropTypes.any.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  order: PropTypes.number,
  previous: PropTypes.string,
  role: PropTypes.string,
  success: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBase);
