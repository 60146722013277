import React from 'react';
import PropTypes from '@prop-types';

import ContextMenu from 'Components/ContextMenu';

const Toolbar = ({ handleDelete }) => (
  <ContextMenu
    right
    options={{
      DELETE_SOURCE: handleDelete,
    }}
  />
);

Toolbar.propTypes = {
  handleDelete: PropTypes.func,
};

export default Toolbar;
